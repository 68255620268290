img {
  display: block;
  max-width: 100%;
}

.hidden-xs {
  display: none;

  @include breakpoint(md) {
    display: block;
  }
}
