.container {
  max-width: 114rem;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;

  @include breakpoint(md) {
    max-width: 96rem;
  }

  @include breakpoint(lg) {
    max-width: 114rem;
  }

  @include breakpoint(xl) {
    max-width: 144rem;
  }

  &--no-padding {
    padding: 0;

    @include breakpoint(md) {
      padding: 0 2rem;
    }
  }
}

.grid {
  display: grid;
  gap: 3rem;
  margin: 0 0 3rem 0;

  @include breakpoint(md) {
    grid-template-columns: repeat(2, 1fr);

    &--3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.column {
  @include breakpoint(md) {
    &--span {
      grid-column: 1 / -1;
    }
  }
}

.hide-sm-up {
  display: block;

  @include breakpoint(sm) {
    display: none;
  }
}

.mb-30 {
  margin-bottom: 3rem;
}
