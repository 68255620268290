.article {
  display: flex;
  flex-direction: column;
  border: 0.1rem solid #d8d7d7;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  height: 100%;
  overflow: hidden;

  &:after {
    display: block;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    height: 0.5rem;
    transition: transform 0.2s ease-out;
    background: $blog-studying;
    transform: translateY(100%);
  }

  &:hover {
    &:after {
      transform: translateY(0);
    }
  }

  &--graduates {
    &:after {
      background: $blog-graduates;
    }
  }

  &--working {
    &:after {
      background: $blog-working;
    }
  }

  &--studying {
    &:after {
      background: $blog-studying;
    }
  }

  &--lifestyle {
    &:after {
      background: $blog-lifestyle;
    }
  }
}

.article__image {
  height: 14rem;
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.article__content {
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.article__category {
  position: absolute;
  left: 1.5rem;
  background: $blog-studying;
  font-size: 1rem;
  padding: 0.8rem 1.2rem;
  text-transform: uppercase;
  font-weight: 700;
  top: 14rem;
  transform: translateY(-50%);
  color: $white;

  .article--studying & {
    background: $blog-studying;
  }

  .article--working & {
    background: $blog-working;
  }

  .article--lifestyle & {
    background: $blog-lifestyle;
    color: #333;
  }

  .article--graduates & {
    background: $blog-graduates;
  }
}

.article__author {
  color: #9c9c9c;
  font-size: 1.2rem;

  p {
    margin: 0;
  }
}
