.share {
  background: #f1f1f1;
  border: 0.1rem solid #cccccc;
  color: $surface-blue;
  text-decoration: none;
  font-size: 1.4rem;
  align-items: center;
  display: inline-flex;
  padding: 0 1.2rem 0 0;
  transition: background-color 0.1s $default-transition-ease,
    border-color 0.1s $default-transition-ease, color 0.1s $default-transition-ease;
  cursor: pointer;
  z-index: 3;

  &:hover {
    border-color: $deep-water;
    color: $deep-water;
  }

  svg {
    display: block;
    border-right: 0.1rem solid #cccccc;
    width: 1.4rem;
    padding: 1.1rem 1.2rem;
    margin-right: 1.2rem;
    color: $deep-water;
    transition: color 0.1s $default-transition-ease;
  }

  &--reverse {
    background: transparent;
    border-color: transparent;
    color: $white;
    font-size: 1.6rem;

    svg {
      border-color: transparent;
      margin: 0;
      color: $white;
      width: 2.4rem;
    }

    &:hover {
      border-color: transparent;

      svg {
        color: $deep-water;
      }
    }
  }
}
