.enz-region-info-list {
  @include breakpoint(sm) {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__trigger {
    width: 100%;
    background-color: $white;
    color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    padding: 1.4rem 2rem;
    font-weight: $font-weight-bold;

    &:focus {
      outline: none;
    }

    @include breakpoint(sm) {
      display: none;
    }
  }

  &__region {
    border-bottom: 1px solid $light-grey;

    &:first-child {
      border-top: 1px solid $light-grey;
    }

    @include breakpoint(sm) {
      border-bottom: none;
      &:first-child {
        border-top: none;
      }
    }

    &.is-selected {
      height: 100%;

      .enz-region-info-list {
        &__trigger {
          background-color: $primary-color;
          color: $white;
        }
      }
    }
  }
}

.enz-region-info {
  background-color: $deep-water-10;
  height: 100%;

  @include breakpoint(sm) {
    background-color: $white;
  }

  // reponsive background image (445px x 297px)
  &__image {
    position: relative;
    background-position: center;
    background-size: cover;
    background-color: $primary-color;

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: (297 / 445) * 100%;
    }

    &__ratio {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity $default-transition-duration $default-transition-ease;

      &--loaded {
        opacity: 1;
      }
    }

    img {
      width: 100%;
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    z-index: 1;

    @include breakpoint(sm) {
      display: block;
    }
  }

  &__content {
    padding: 3.2rem;

    &__title {
      margin-bottom: 2rem;
    }

    &__desc {
      margin-bottom: 3.2rem;
    }
  }
}
