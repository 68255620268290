// Colors
$black: #000;
$white: #fff;
$light-grey: #cdd1d7;
$surface-blue: #22a2a2;
$map-blue: #cfe9f2;
$deep-water: #071937;
$deep-water-10: #e6e8eb;
$deep-water-20: #cdd1d7;
$deep-water-60: #6a7587;
$east-coast-sand: #e0c7a7;
$manapou-orangedark: #b73d1c;
$ebony: #0d1626;
$grey: #e5e5e5;

$blog-working: #df532d;
$blog-studying: $surface-blue;
$blog-lifestyle: $east-coast-sand;
$blog-graduates: #4c5662;

$primary-color: $deep-water;
$primary-hover-color: #27354f;
$secondary-color: $surface-blue;
$secondary-hover-color: #27354f;

$body-font-colour: $black;
$body-link-colour: $secondary-color;

$footer-background: #222;
$footer-foreground: #fff;
$map-path-stroke-color: #9ac6ac;
$map-path-fill-color: #bfdecc;
$map-path-active-fill-color: #9ac6ac;
$map-label-background-hover-color: $primary-color;

// Fonts
$body-font-family: 'Untitled', Helvetica, Arial, sans-serif;
$heading-font-family: 'A23D Solid', sans-serif;

$font-weight-regular: 400;
$font-weight-bold: 700;

// Transitions
$default-transition-duration: 0.2s;
$default-transition-ease: ease-out;

// Layout
$container-lg: 980px;
$container-md: 750px;

// Breakpoints
$breakpoints: (
  'xxxs': 375px,
  'xxs': 460px,
  'xs': 540px,
  'sm': 768px,
  'md': 900px,
  'lg': 1200px,
  'xl': 1800px
);

@mixin breakpoint($name) {
  @media (min-width: map-get($breakpoints, $name)) {
    @content;
  }
}
