$stage1-duration: 0.1s;
$stage2-duration: 0.3s;

.toggle {
  @extend .container;
  display: flex;
  background: $deep-water;
  flex-direction: column;
  padding: 0;
  position: relative;
  transition: box-shadow $stage1-duration 0s,
    margin $stage2-duration $stage1-duration $default-transition-ease;

  &--active {
    padding: 0 0 4.3rem 0;
  }

  @include breakpoint(md) {
    flex-direction: row;
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
    margin: -14rem auto 4rem auto;
    padding: 0;
    position: relative;
    justify-content: center;
    background: transparent;
  }

  p {
    margin: -1px 0 0;
  }
}

.toggle--active {
  box-shadow: none;

  @include breakpoint(md) {
    margin-top: -24rem;
  }

  .toggle__option {
    @include breakpoint(md) {
      background: transparent;
      border-color: transparent;
      padding: 2rem;
      flex-grow: 0;
      flex-basis: auto;

      &:hover {
        background: transparent;
      }

      &:not(.toggle__option--selected):hover {
        .toggle__icon {
          background: $deep-water-10;
        }
      }
    }
  }

  .toggle__icon {
    @include breakpoint(md) {
      width: 18rem;
      height: 18rem;
      background: #cdd1d7;

      svg {
        width: 11rem;
        height: 11rem;
      }
    }
  }

  .toggle__label {
    @include breakpoint(md) {
      opacity: 0;
      font-size: 0;
    }
  }
}

.toggle__intro {
  background: $deep-water;
  text-align: center;
  padding: 0 0 3rem 0;
  color: rgba(255, 255, 255, 0.6);

  @include breakpoint(sm) {
    padding: 3rem 0 3rem 0;
    font-size: 1.8rem;
  }

  @include breakpoint(md) {
    display: none;
  }
}

.toggle__label {
  transition: opacity $stage1-duration 0s $default-transition-ease,
    font-size $stage2-duration $stage1-duration $default-transition-ease;
}

.toggle__option {
  display: flex;
  padding: 1.5rem 2rem;
  align-items: center;
  border: 0.1rem solid #d4d4d4;
  font-size: 1.8rem;
  text-decoration: none;
  color: $deep-water;
  background: $white;
  transition: background-color $stage1-duration 0s $default-transition-ease,
    border-color $stage1-duration 0s $default-transition-ease,
    padding $stage2-duration $stage1-duration $default-transition-ease,
    flex-basis $stage2-duration $stage2-duration $default-transition-ease;

  @include breakpoint(md) {
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    font-size: 2.2rem;
    padding: 4rem;
    flex-grow: 1;
    flex-basis: 33.33%;

    p {
      margin: 0 0 3rem 0;
    }

    &:hover {
      background: $surface-blue;
      color: $white;
    }
  }

  > * {
    pointer-events: none;
  }

  &--selected {
    background: $surface-blue;
    border-color: $surface-blue;
    color: $white;
  }
}

.toggle__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 50%;
  background: $surface-blue;
  flex-shrink: 0;
  margin: 0 2rem 0 0;
  color: $black;
  transition: all $stage1-duration $default-transition-ease;

  .toggle__option--selected & {
    background: $white;
  }

  @include breakpoint(md) {
    order: 1;
    width: 18rem;
    height: 18rem;
    margin: 0;
    transition: all $stage2-duration $stage1-duration $default-transition-ease;

    .toggle__option:hover & {
      background: $white;
    }

    .toggle__option--selected &,
    .toggle__option--selected:hover & {
      background: $surface-blue;
    }
  }

  @include breakpoint(lg) {
    width: 25rem;
    height: 25rem;
  }

  svg {
    display: block;
    width: 3.5rem;
    height: 3.5rem;
    transition: all $stage2-duration $stage1-duration $default-transition-ease;

    @include breakpoint(md) {
      width: 10rem;
      height: 10rem;
    }

    @include breakpoint(lg) {
      width: 14rem;
      height: 14rem;
    }
  }
}

.toggle__arrow {
  display: none;

  @include breakpoint(md) {
    display: block;
    transform: rotate(90deg);
    position: absolute;
    bottom: -2rem;
    color: $deep-water;
    opacity: 0;
    transition: opacity $stage1-duration ($stage1-duration + $stage2-duration) ease-in-out;

    svg {
      display: block;
    }

    .toggle__option--selected & {
      opacity: 1;
    }
  }
}

.toggle__next {
  transform: rotate(90deg);
  position: absolute;
  bottom: -0.6rem;
  left: 0;
  right: 0;
  top: auto;
  width: 1.3rem;
  height: 2.3rem;
  margin: 0 auto;
  opacity: 0;
  transition: opacity $stage1-duration ease-in-out;
  color: $white;

  .toggle--active & {
    opacity: 1;
  }

  svg {
    display: block;
  }

  @include breakpoint(md) {
    display: none;
  }
}
