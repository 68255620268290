input[type='button'].button,
.button {
  background: $primary-color;
  color: $white;
  display: inline-flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 1.3rem 1.5rem;
  border-radius: 0.3rem;
  border: none;
  text-decoration: none;
  font-family: $heading-font-family;
  text-transform: uppercase;
  font-weight: $font-weight-regular;
  line-height: 1;
  font-size: 2.4rem;
  transition: background-color $default-transition-duration $default-transition-ease,
    color $default-transition-duration $default-transition-ease;

  svg path {
    fill: $white;
    transition: fill $default-transition-duration $default-transition-ease;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: $primary-hover-color;
  }

  &--secondary {
    background: $secondary-color;

    svg path {
      fill: $white;
    }

    &:hover {
      background: $primary-hover-color;
    }
  }

  &--primary-inverse {
    background: $white;
    color: $primary-color;

    svg path {
      fill: $primary-color;
    }

    &:hover {
      color: $white;

      svg path {
        fill: $white;
      }
    }
  }

  &--secondary-inverse {
    background: $white;
    color: $secondary-color;

    svg path {
      fill: $secondary-color;
    }

    &:hover {
      color: $white;

      svg path {
        fill: $white;
      }
    }
  }

  > span {
    &:not(:first-child) {
      margin-left: 0.5rem;
    }
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  .icon {
    height: 1.6rem;
  }
}

.icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  .icon {
    transition: transform $default-transition-duration $default-transition-ease;
    display: block;
  }

  &:hover {
    .icon {
      transform: rotate(90deg);
    }
  }
}

.link-button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 20px 0 0 0;
  display: block;
  position: relative;
  font-weight: $font-weight-bold;
  text-decoration: underline;

  @include breakpoint(xxs) {
    margin: 0 0 0 20px;
    display: inline-block;
  }

  &:focus {
    outline: none;
  }

  &--secondary {
    color: $secondary-color;
  }

  &--inverse {
    color: $white;
  }

  svg {
    transition: transform 0.1s linear;
    position: absolute;
    top: 0;
    right: -20px;
    width: 10px;
    transform: rotate(90deg);
  }

  &--active {
    svg {
      transform: rotate(270deg) scaleY(-1);
    }
  }
}
