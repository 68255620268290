.footer {
  background: $footer-background;
  padding: 4.8rem 0 5.4rem 0;
  color: $white;

  a {
    color: $white;
    text-decoration: none;
    font-weight: $font-weight-bold;
  }

  p {
    margin: 0;
  }
}

.footer__primary {
  @include breakpoint(sm) {
    display: flex;
    justify-content: space-between;
  }
}

.footer__secondary {
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;

  @include breakpoint(sm) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  p {
    margin-top: 1rem;
    order: 1;

    @include breakpoint(sm) {
      order: 0;
    }
  }
}

.footer__list {
  margin: 0;
  padding: 1.28rem 0;
  list-style: none;

  @include breakpoint(sm) {
    display: flex;
    padding: 1.92rem 0 4rem 0;
    flex-wrap: wrap;
  }

  li {
    margin: 0 0 1.92rem 0;

    @include breakpoint(sm) {
      margin: 0 6.4rem 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.footer__list-social {
  margin: 0 0 1.92rem 0;
  padding: 1.92rem 0 0 0;
  list-style: none;
  display: flex;

  @include breakpoint(sm) {
    padding-bottom: 4rem;
  }

  li {
    margin: 0 0 1.92rem 0;
    width: 50%;

    img {
      width: 2rem;
      height: 2rem;
      display: inline-block;
      margin-right: 0.96rem;
      position: relative;
      top: 0.3rem;
    }

    @include breakpoint(sm) {
      margin: 0 6.4rem 0 0;
      width: auto;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.footer__imprimateur {
  padding: 3rem 0 0 0;
  margin: 0 0 5rem 0;
  display: flex;

  p {
    font-size: 1.2rem;
    line-height: 1.5;
    position: relative;

    @include breakpoint(md) {
      &:after {
        position: absolute;
        top: 12.2rem;
        left: 0;
        content: '';
        height: 0.1rem;
        width: 6.5rem;
        background-color: grey;
      }
    }
  }

  &-content {
    margin: 0.6rem 0 0 0;

    @include breakpoint(sm) {
      width: 26rem;
      margin-right: 3.2rem;
    }
  }
}

.footer__coatofarms {
  height: 6.5rem;
  width: 6.9rem;
  margin-right: 3rem;
}

.footer__logo {
  height: 3.2rem;
  margin: 0 0 1.92rem 0;

  @include breakpoint(sm) {
    margin: 0;
  }
}
