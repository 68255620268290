.enz-map-icons {
  &__icon {
    position: absolute;

    span {
      display: inline-block;
      position: absolute;
      bottom: 0;
      transform: translateX(-50%);
      background-image: url(../../assets/images/map/icons-sprite.png);
      background-position: 0 -331px;
      width: 22px;
      height: 31px;
    }

    // Icon placement
    &.cabbage-trees {
      left: 51.5%;
      top: 14.5%;
    }

    &.marae {
      left: 58%;
      top: 19%;
    }

    &.sky-tower {
      left: 62%;
      top: 27%;
    }

    &.clapper-board {
      left: 66%;
      top: 33%;
    }

    &.geyser {
      left: 77%;
      top: 37%;
    }

    &.surfer {
      left: 89%;
      top: 36%;
    }

    &.grapes {
      left: 77.5%;
      top: 42.5%;
    }

    &.coffee {
      left: 63%;
      top: 56%;
    }

    &.canoe-1 {
      left: 67%;
      top: 47%;
    }

    &.canoe-2 {
      left: 55%;
      top: 53%;
    }

    &.mountain-1-1 {
      left: 59%;
      top: 42.5%;
    }

    &.mountain-1-2 {
      left: 69%;
      top: 41.5%;
    }

    &.mountain-2 {
      left: 42%;
      top: 64.5%;
    }

    &.skier {
      left: 71.5%;
      top: 44%;
    }

    &.mountains-1 {
      left: 51%;
      top: 58%;
    }

    &.mountains-2 {
      left: 28%;
      top: 72.5%;
    }

    &.jet-boat {
      left: 32%;
      top: 71%;
    }

    &.whale-tail {
      left: 57%;
      top: 65.5%;
    }

    &.kea {
      left: 36%;
      top: 65%;
    }

    &.snow-boarder {
      left: 44.5%;
      top: 64%;
    }

    &.cyclist {
      left: 33%;
      top: 75.5%;
    }

    &.penguin {
      left: 28%;
      top: 87%;
    }

    // Sprite positions
    &.cabbage-trees span {
      background-position: 0 -331px;
      width: 22px;
      height: 31px;
    }

    &.marae span {
      background-position: 0 -52px;
      width: 30px;
      height: 20px;
    }

    &.sky-tower span {
      background-position: 0 -469px;
      width: 21px;
      height: 56px;
    }

    &.clapper-board span {
      background-position: 0 -92px;
      width: 22px;
      height: 22px;
    }

    &.geyser span {
      background-position: 0 -362px;
      width: 19px;
      height: 32px;
    }

    &.surfer span {
      background-position: 0 -139px;
      width: 33px;
      height: 25px;
    }

    &.grapes span {
      background-position: 0 -217px;
      width: 17px;
      height: 28px;
    }

    &.coffee span {
      background-position: 0 -429px;
      width: 37px;
      height: 40px;
    }

    &.canoe span {
      background-position: 0 -164px;
      width: 33px;
      height: 26px;
    }

    &.mountain-1 span {
      background-position: 0 0;
      width: 29px;
      height: 15px;
    }

    &.mountain-2 span {
      background-position: 0 -15px;
      width: 36px;
      height: 18px;
    }

    &.skier span {
      background-position: 0 -245px;
      width: 44px;
      height: 28px;
    }

    &.mountains span {
      background-position: 0 -190px;
      width: 49px;
      height: 27px;
    }

    &.jet-boat span {
      background-position: 0 -72px;
      width: 37px;
      height: 20px;
    }

    &.whale-tail span {
      background-position: 0 -33px;
      width: 33px;
      height: 19px;
    }

    &.kea span {
      background-position: 0 -114px;
      width: 26px;
      height: 25px;
    }

    &.snow-boarder span {
      background-position: 0 -302px;
      width: 27px;
      height: 29px;
    }

    &.cyclist span {
      background-position: 0 -273px;
      width: 26px;
      height: 29px;
    }

    &.penguin span {
      background-position: 0 -394px;
      width: 19px;
      height: 35px;
    }
  }
}
