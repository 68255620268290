.countdown-timer {
  display: flex;

  h4 {
    margin: 0;
  }

  &--spacing {
    margin-bottom: 1.6rem;
  }

  &--spacing-x2 {
    margin-bottom: 3.2rem;

    @include breakpoint(md) {
      margin-right: 4rem;
    }

    @include breakpoint(lg) {
      margin-right: 8rem;
    }
  }

  &__splash {
    background-image: url('../../assets/images/generated/cards/fair-module-730.png');
    background-position: center;
    background-size: cover;
    display: none;
    width: calc(50% - 1.5rem);
    margin-right: 1.5rem;
    flex-shrink: 1;

    @include breakpoint(sm) {
      display: block;
    }
  }

  &__timer {
    background-color: $deep-water-60;
    color: $white;
    padding: 5rem 4rem;

    @include breakpoint(sm) {
      min-width: 540px;
      width: calc(50% - 1.5rem);
      margin-left: 1.5rem;
      padding: 8rem 0;
    }
  }

  &__groups {
    font-family: $heading-font-family;
    display: flex;
  }

  &__group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 1.2rem;

    &:last-child {
      margin-right: 0;
    }

    @include breakpoint(xxs) {
      margin-right: 2rem;
    }

    @include breakpoint(sm) {
      display: block;
      margin: 0;
    }
  }

  &__unit {
    font-size: 1.4rem;
    color: $deep-water-20;
    opacity: 0.8;

    @include breakpoint(sm) {
      margin-right: 1rem;
      font-size: 1.6rem;
    }
  }

  &__value {
    display: inline-block;
    font-size: 4rem;
    font-weight: bold;
    text-align: right;
    line-height: 1;

    @include breakpoint(sm) {
      font-size: 6rem;
      line-height: inherit;
    }
  }

  &__toggle-section {
    position: relative;
    display: none;

    &--visible {
      display: block;
    }

    a {
      color: $white;
    }
  }
}

.countdown-cta-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.countdown-cta-list {
  @include breakpoint(xs) {
    display: flex;
  }

  ul {
    list-style-type: disc;
    margin: 0;

    @include breakpoint(xs) {
      &:first-child {
        padding-left: 10px;
      }

      width: 50%;
    }
  }
}
