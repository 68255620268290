.card {
  background: $surface-blue;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
  height: 100%;
  color: $white;

  @include breakpoint(md) {
    flex-direction: row;
  }

  h3 {
    margin-bottom: 1.2rem;
    font-size: 2.8rem;
  }
}

.card__image {
  display: none;
  background: black;
  flex: 0 1 50%;
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card--large & {
    height: 16rem;
    flex: 0 1 auto;
    // display: block;
  }

  @include breakpoint(md) {
    flex: 0 1 50%;
    flex-shrink: 0;
    display: block;

    .card--large & {
      flex: 0 0 55%;
      height: auto;
    }
  }
}

.card--dark-grey {
  background: #6a7587;
}

.card--orange {
  background: #b73d1c;
}

.card--dark-green {
  background: #34431c;
}

.card__content {
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
}
