// custom Font
@font-face {
  font-family: 'Untitled';
  src: url('../assets/fonts/untitled-sans-web-regular.eot');
  src: url('../assets/fonts/untitled-sans-web-regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/untitled-sans-web-regular.woff') format('woff'),
    url('../assets/fonts/untitled-sans-web-regular.woff2') format('woff2');
  font-weight: $font-weight-regular;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Untitled';
  src: url('../assets/fonts/untitled-sans-web-bold.eot');
  src: url('../assets/fonts/untitled-sans-web-bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/untitled-sans-web-bold.woff') format('woff'),
    url('../assets/fonts/untitled-sans-web-bold.woff2') format('woff2');
  font-weight: $font-weight-bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'A23D Solid';
  src: url('../assets/fonts/A23D_Solid-RegularWEB.woff') format('woff'),
    url('../assets/fonts/A23D_Solid-RegularWEB.woff2') format('woff2');
  font-weight: $font-weight-regular;
  font-style: normal;
  text-transform: uppercase;
  font-display: swap;
}

html {
  font-size: 62.5%;
}

body {
  font-family: $body-font-family;
  font-size: 1.6rem;
  line-height: 1.44;
  color: $body-font-colour;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $heading-font-family;
  text-transform: uppercase;
  font-weight: $font-weight-regular;
  line-height: 1.2;
  color: $black;
}

h1 {
  font-size: 3.2rem;
  margin: 0 0 1.6rem 0;

  @include breakpoint(sm) {
    font-size: 6.8rem;
  }
}

h2 {
  font-size: 3rem;
  margin: 0 0 3rem 0;
  text-align: center;

  @include breakpoint(sm) {
    font-size: 4.5rem;
  }
}

h3 {
  font-size: 2.4rem;
  margin: 0 0 1.6rem 0;

  @include breakpoint(sm) {
    font-size: 3.5rem;
  }
}

h4 {
  font-size: 2.2rem;
  margin: 0 0 1.6rem 0;

  @include breakpoint(sm) {
    font-size: 2.4rem;
  }
}

p {
  margin: 0 0 1.6rem 0;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: $body-link-colour;
  text-decoration: underline;
}

a.a-href {
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.lead {
  max-width: 80rem;
  margin: 0 auto 1.6rem auto;
  text-align: center;

  @include breakpoint(sm) {
    font-size: 1.8rem;
  }

  &--intro {
    @include breakpoint(sm) {
      font-size: 2.4rem;
    }
  }
}

.text-centered {
  text-align: center;
}

.text-left {
  text-align: left;
}
