.enz-map-labels {
  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translateX(-50%) translateY(calc(-50% - 16px));
    transition: transform 0.1s $default-transition-ease, opacity 0.1s $default-transition-ease;
    pointer-events: none;
  }

  &__label {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    background: $white;
    border: 0;
    border-radius: 2px;
    color: $secondary-color;
    padding: 0rem 0.5rem;
    // display: flex;
    font-size: 1.4rem;
    transition: background-color $default-transition-duration $default-transition-ease,
      color $default-transition-duration $default-transition-ease;
    transform: translateY(-100%);
    max-width: 120px;
    white-space: nowrap;

    @include breakpoint(xxxs) {
      padding: 0.2rem 1rem;
      max-width: 140px;
    }

    @include breakpoint(xxs) {
      padding: 0.6rem 1rem;
      max-width: none;
    }

    @include breakpoint(sm) {
      font-size: 1.6rem;
    }

    &:hover {
      background-color: $secondary-color;
      color: $white;
    }

    &.is-selected {
      background-color: $primary-color;
      color: $white;

      .enz-map-labels__icon {
        opacity: 1;
        transform: translateX(-50%) translateY(calc(-50%));
      }
    }

    &.right {
      transform: translateX(-100%) translateY(-100%);
    }

    &:focus {
      outline: none;
    }

    // northland
    &--northland {
      top: 16%;
      left: 57%;
    }

    // auckland
    &--auckland {
      top: 26.5%;
      left: 60%;
    }

    // tauranga
    &--tauranga {
      top: 31%;
      left: 70%;
    }

    // rotorua
    &--rotorua {
      top: 37%;
      left: 80%;
    }

    // waikato
    &--waikato {
      top: 37%;
      left: 62%;
    }

    // gisborne
    &--gisborne {
      top: 43%;
      left: 83%;
    }

    // hawkes-bay
    &--hawkes-bay {
      top: 48.75%;
      left: 77%;
    }

    // taranaki
    &--taranaki {
      top: 43%;
      left: 56%;
    }

    // manawatu
    &--whanganui {
      top: 49%;
      left: 65%;
    }

    // manawatu
    &--manawatu {
      top: 54.5%;
      left: 68%;
    }

    // wellington
    &--wellington {
      top: 60%;
      left: 66%;
    }

    // nelson-marlborough
    &--nelson-marlborough {
      white-space: normal;
      top: 60%;
      left: 50%;

      @include breakpoint(xxs) {
        top: 55%;
        left: 50%;
      }
    }

    // west-coast
    &--west-coast {
      top: 67%;
      left: 32%;

      @include breakpoint(xxs) {
        top: 61%;
        left: 41%;
      }
    }

    // canterbury
    &--canterbury {
      top: 70%;
      left: 52%;
    }

    // otago
    &--otago {
      top: 80%;
      left: 39%;
    }

    // queenstown
    &--queenstown {
      top: 75%;
      left: 23%;
    }

    // southland
    &--southland {
      top: 83%;
      left: 24%;
    }
  }
}
