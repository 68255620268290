.header {
  // background: rgba(34, 34, 34, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  color: $white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  padding: 0 1.6rem;

  @include breakpoint(sm) {
    height: 7rem;
    padding: 0 4rem;
  }

  a {
    color: $white;
    text-decoration: none;
  }
}

.header__left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @include breakpoint(sm) {
    justify-content: flex-start;
  }
}

.header__logo {
  margin-right: 1.6rem;
  width: 15.6rem;

  svg {
    display: block;
    width: 100%;
  }

  @include breakpoint(sm) {
    margin-right: 4rem;
    width: 21.3rem;
  }
}

.header__think-new-logo {
  display: none;
  height: 32px;

  @include breakpoint(lg) {
    display: block;
  }

  svg {
    display: block;
    height: 100%;
  }
}

a.header__home-link {
  font-size: 1.4rem;
  color: $white;
  text-align: right;

  &:after {
    font-weight: $font-weight-bold;
    padding-left: 0.64rem;
    content: '\203A';
    position: relative;
    top: -0.1rem;
  }

  @include breakpoint(sm) {
    display: none;
  }
}

.breadcrumbs {
  display: none;

  @include breakpoint(sm) {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1.4rem;
    text-shadow: 0 0 0.4rem rgba(15, 25, 40, 0.1);
  }

  li {
    padding: 0 0.64rem 0 1.2rem;
    position: relative;

    &:first-child {
      padding-left: 0;
    }

    &:not(:first-child):before {
      font-weight: $font-weight-bold;
      content: '\203A';
      position: absolute;
      top: -0.1rem;
      left: 0;
    }

    &:last-child {
      font-weight: $font-weight-bold;
    }
  }
}
