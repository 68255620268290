.enz-map {
  background-color: $map-blue;
  position: relative;
  max-width: 600px;
  margin: auto;

  &__content {
    position: relative;
    z-index: 1;
    padding: 12%;
  }

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/map/map-background.png);
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  &__scale {
    position: absolute;
    bottom: 12%;
    right: 12%;
    display: none;

    @include breakpoint(sm) {
      display: block;
    }
  }
}
