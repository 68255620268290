.section {
  padding: 5rem 0;

  @include breakpoint(sm) {
    padding: 8rem 0;
  }

  /* Spacing modifiers */
  &--margin-btm {
    margin-bottom: 3rem;

    @include breakpoint(sm) {
      margin-bottom: 5rem;
    }
  }

  &--compact {
    padding: 4rem 0;

    @include breakpoint(sm) {
      padding: 5rem 0;
    }
  }

  &--perfect-match-above {
    @include breakpoint(sm) {
      padding-top: 0;
    }
  }

  &--perfect-match-below {
    padding-top: 0;
  }

  &--pb0 {
    padding-bottom: 1px;

    @include breakpoint(sm) {
      padding-bottom: 2rem;
    }
  }

  /* Color modifiers */
  &--blue {
    background: $deep-water;
    color: $white;

    h2 {
      color: $white;
    }

    p > a {
      color: $white;
      text-decoration: underline;
    }

    svg path {
      fill: $white;
    }

    &:hover {
      color: $white;

      svg path {
        fill: $white;
      }
    }
  }

  &--grey {
    background: $grey;
  }

  &--orange {
    background: $manapou-orangedark;
    color: $white;

    h2 {
      color: $white;
    }

    p > a {
      color: $white;
      text-decoration: underline;
    }
  }

  &--dark-grey {
    background: $deep-water-60;
    color: $white;
  }

  &--map-blue {
    background: $map-blue;
  }

  &--toggle {
    background: $white;
    padding: 0;

    @include breakpoint(md) {
      padding: 0 0 4rem 0;
    }
  }

  &--secondary {
    background: $surface-blue;
    color: $white;
  }
}
