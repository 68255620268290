.banner {
  text-align: center;
  position: relative;

  @include breakpoint(md) {
    padding: 10rem 0 32rem 0;

    &--noimage {
      padding: 6rem 0;
    }

    &:after {
      position: absolute;
      content: '';
      display: block;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0;
      background: $white;
      transition: height 0.2s 0.1s $default-transition-ease;
    }

    &--active {
      &:after {
        height: 10rem;
      }
    }
  }

  &--noimage {
    background: $deep-water;
  }
}

.banner__content {
  background: $deep-water;
  padding: 4rem 3rem 2rem 3rem;
  position: relative;
  z-index: 2;

  .banner--noimage & {
    padding: 8rem 3rem 4rem 3rem;
  }

  @include breakpoint(md) {
    background: transparent;
  }

  h1 {
    color: $white;
    margin: 0;

    @include breakpoint(md) {
      color: $deep-water;
      margin: 0 auto;
    }

    .banner--noimage & {
      color: $white;
    }
  }

  h3 {
    color: $white;
    margin: 2rem auto 0;
    font-size: 2rem;
    max-width: 25rem;

    @include breakpoint(sm) {
      font-size: 3.5rem;
      max-width: 50rem;
    }

    @include breakpoint(md) {
      color: $deep-water;
      max-width: 85rem;
    }
  }

  &__subtitle {
    margin-top: 1rem;
    color: rgba(255, 255, 255, 0.6);
  }
}

.banner__image {
  height: 16rem;
  position: relative;

  @include breakpoint(sm) {
    height: 30vh;
  }

  @include breakpoint(md) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 1px;
    height: auto;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include breakpoint(md) {
      // object-position: 50% 20%;
    }
  }
}

.banner__overlays {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
}

.banner__overlay {
  position: absolute;
  top: 0%;
  bottom: 0%;

  // base overlay
  &--0 {
    left: 0%;
    right: 0%;
    background: #071937;
    opacity: 0.1;
    transform: rotate(-180deg);

    @include breakpoint(sm) {
      display: none;
    }
  }

  // full width vertical gradient 1
  &--1 {
    left: 0%;
    right: 0%;
    background: linear-gradient(180deg, #012155 0%, rgba(7, 25, 55, 0) 27.97%);
    opacity: 0.3;

    @include breakpoint(sm) {
      opacity: 0.4;
    }
  }

  // full width vertical gradient 2
  &--2 {
    left: 0%;
    right: 0%;
    background: linear-gradient(180deg, #071937 0%, rgba(7, 25, 55, 0) 30.42%);
    opacity: 0.3;
    transform: rotate(-180deg);

    @include breakpoint(sm) {
      opacity: 0.4;
    }
  }

  // left horizontal gradient
  &--3 {
    left: 0;
    right: 70%;
    background: linear-gradient(302.6deg, #071937 5.07%, rgba(7, 25, 55, 0) 53.99%);
    opacity: 0.1;
    transform: rotate(-180deg);

    @include breakpoint(sm) {
      opacity: 0.2;
    }
  }

  // right horizontal gradient
  &--4 {
    left: 70%;
    right: 0;
    background: linear-gradient(302.6deg, #071937 5.07%, rgba(7, 25, 55, 0) 53.99%);
    opacity: 0.1;
    transform: matrix(1, 0, 0, -1, 0, 0);

    @include breakpoint(sm) {
      opacity: 0.2;
    }
  }
}

.banner__share {
  @extend .container;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .share {
    position: absolute;
    right: 0;
    top: 0;
    transition: bottom 0.2s 0.2s ease-in-out;

    // Position bottom-right on map page
    .banner--noimage & {
      bottom: 0;
      top: auto;
    }

    @include breakpoint(md) {
      top: auto;
      bottom: 15.6rem;

      .banner--active & {
        bottom: 10rem;
      }

      .banner--noimage & {
        bottom: 0;
        right: 2rem;
      }
    }
  }
}
