.enz-region-map {
  overflow: hidden;

  @include breakpoint(sm) {
    display: flex;
  }

  &__map {
    @include breakpoint(sm) {
      flex: 0 0 58.3333333333%;
    }

    &__padding {
      padding: 0 2rem;
    }
  }

  &__list {
    position: relative;
    overflow: hidden;

    @include breakpoint(sm) {
      flex: 0 0 41.6666666667%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $surface-blue;
    display: none;
    z-index: 0;

    @include breakpoint(sm) {
      display: block;
    }

    &__content {
      padding: 6.4rem 0.8rem;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;
    }
  }
}
