// override market styles to look like this form: https://www.studyinnewzealand.govt.nz/asknewanything/
.marketoFormWrapper {
  color: $white !important;
  background-color: $surface-blue;
  max-width: 63rem;
  margin: 0 auto;

  form {
    width: auto !important;
    margin: 0 auto !important;
  }

  a {
    color: $white !important;
  }

  .mktoForm {
    font-family: $body-font-family !important;
    padding: 0 !important;
  }

  .mktoForm {
    .mktoFormRow {
      display: flex;
      flex-direction: column;

      @include breakpoint(xs) {
        flex-direction: row;
      }
    }

    .mktoHtmlText.mktoHasWidth strong {
      color: $white !important;
      font-size: 1.6rem;
      padding: 0;
    }

    .mktoFormCol {
      margin: 0 0 2rem !important;

      @include breakpoint(xs) {
        &:nth-child(2),
        &:nth-child(3) {
          padding-left: 1.5rem !important;
        }
      }
    }

    .mktoFormCol,
    .mktoFieldWrap,
    .mktoButtonRow {
      float: none;
      width: 100%;
    }

    .mktoButtonWrap.mktoRound {
      display: flex;
      justify-content: center;

      .mktoButton {
        @extend .button;
        border: none;
        width: 100%;
        justify-content: center;
        box-sizing: border-box;

        &:hover {
          border: none;
        }
      }
    }

    .mktoField {
      height: 5.2rem;
      box-sizing: border-box;
      margin: 0 !important;
      padding: 1rem !important;
    }

    .mktoError {
      right: auto !important;
      bottom: auto !important;
      top: 8.3rem;

      .mktoErrorArrowWrap {
        display: none;
      }

      .mktoErrorMsg {
        background-color: transparent;
        background-image: none;
        border: none;
        box-shadow: none;
        color: $manapou-orangedark;
        margin-top: 5px;
        padding: 0;
        text-shadow: none;
      }

      #ValidMsgEmail,
      #ValidMsgMobilePhone {
        width: 300px;
        max-width: none;
        & span {
          display: inline;
        }
      }
    }

    .mktoLabel {
      color: $white !important;
      font-size: 1.6rem !important;
      font-weight: normal !important;
      margin: 0 0 8px;
    }

    .mktoOffset {
      display: none;
    }

    input,
    select,
    textarea {
      background: rgba(13, 22, 38, 0.1) !important;
      color: rgba(255, 255, 255, 0.5) !important;
      border: none !important;
      border-bottom: 1px solid $white !important;
      box-shadow: none !important;
      width: 100% !important;
      border-radius: 0 !important;
      font-size: 1.6rem !important;

      &.mktoInvalid {
        border-bottom: 0.3rem solid $manapou-orangedark !important;
      }
    }

    select {
      background-image: url(../../assets/images/chevron-down.svg) !important;
      background-repeat: no-repeat !important;
      background-position: 96% !important;
      cursor: pointer;

      @include breakpoint(lg) {
        background-position: 99% !important;
      }

      option {
        color: $black;
      }
    }

    #LbleNZPrivacyPolicyAgreed,
    #LbleNZINZMatchingPermission,
    #LbleNZMarketoFormOptIn {
      padding-left: 4rem !important;
      line-height: 1.4 !important;
      font-size: 1.4rem !important;
      width: auto !important;
      z-index: 10;
      position: relative;

      a {
        padding: 0;
      }
    }

    #eNZNationality {
      margin-bottom: 1.2rem !important;
    }

    #ValidMsgeNZPrivacyPolicyAgreed,
    #ValidMsgeNZINZMatchingPermission {
      margin-top: -10rem;
    }

    #LbleNZComments {
      width: 100% !important;

      a,
      strong {
        padding: 0 !important;
      }
    }

    #eNZComments {
      display: none;
    }

    .mktoGutter {
      display: none;
    }

    input[type='checkbox'] + label:before {
      top: 0.3rem;
      left: 0.1rem;
      border-radius: 3px;
      border: 1px solid #fff !important;
      background-color: rgba(13, 22, 38, 0.1) !important;
      box-shadow: none !important;
      color: #fff !important;
      font-size: 18px;
      padding: 2px;
      margin-right: 50px;
    }

    div.mktoInvalid {
      border-color: transparent !important;
    }

    .mktoLogicalField {
      position: absolute;
    }

    .mktoLogicalField.mktoInvalid label:before {
      border-color: $manapou-orangedark !important;
    }

    .checkbox-accordion-content,
    .mktoAsterix {
      display: none !important;
    }

    .mktoButtonRow {
      display: block;
    }

    .mktoButtonWrap.mktoRound {
      margin-left: 0 !important;

      .mktoButton {
        @extend .button;
      }
    }
  }
}

.registration-form-success {
  text-align: center;
  margin: 0 auto;

  &__content {
    margin-bottom: 2rem;

    a {
      color: $white;
      font-weight: $font-weight-bold;
    }
  }
}
