.cta-banner {
  color: $white;
  max-width: 920px;
  margin: auto;

  @include breakpoint(sm) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    margin-top: 2rem;

    @include breakpoint(sm) {
      margin-top: 0;
    }
  }

  &__content {
    @include breakpoint(sm) {
      width: 70%;
    }

    @include breakpoint(lg) {
      width: 60%;
    }
  }
}
